import { Notice } from '@/entities/notice';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { commonHOC } from '../src/component';
import { loadPageItems, type StaticProps } from '../src/contentful/ssr';
import { HomepageTemplate, Page } from '../src/template';
import { HomepageSlug } from '../src/types';

export const getServerSideProps: GetServerSideProps<StaticProps> = async (context) =>
	loadPageItems(HomepageSlug, context);

export const Home = (staticProps: InferGetServerSidePropsType<typeof getServerSideProps>) => {
	const children =
		staticProps.content?.__typename !== 'HomepageTemplate' ? (
			<Notice text="Invalid content returned from CMS" />
		) : (
			<HomepageTemplate content={staticProps.content} />
		);

	return <Page {...staticProps}>{children}</Page>;
};

export default commonHOC(Home, 'Home');
